import { createGlobalStyle } from 'styled-components'
import { Colors, FontSizes, Timing } from 'theme'

// @ts-ignore
import gothicBoldWoff from './assets/fonts/gothic-a1-v13-latin-700.woff'
// @ts-ignore
import gothicBoldWoff2 from './assets/fonts/gothic-a1-v13-latin-700.woff2'
// @ts-ignore
import gothicRegularWoff from './assets/fonts/gothic-a1-v13-latin-regular.woff'
// @ts-ignore
import gothicRegularWoff2 from './assets/fonts/gothic-a1-v13-latin-regular.woff2'

const AppStyles = createGlobalStyle`
  @font-face {
    font-family: 'Gothic A1';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url(${gothicRegularWoff2}) format('woff2'),
        url(${gothicRegularWoff}) format('woff');
  }
  /* gothic-a1-700 - latin */
  @font-face {
    font-family: 'Gothic A1';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url(${gothicBoldWoff2}) format('woff2'),
        url(${gothicBoldWoff}) format('woff');
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    background: ${Colors.Base};
    color: ${Colors.Text};
    font-family: 'Gothic A1', -apple-system, Helvetica, Arial, sans-serif;
    margin: 0;
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }

  a, button, p, span, b, i, input, textarea, li, label {
    font-family: 'Gothic A1', -apple-system, Helvetica, Arial, sans-serif;
    font-weight: 400;
  }

  p {
    font-size: ${FontSizes.Base};
    line-height: 1.4;
  }

  span {
    font-size: ${FontSizes.Base};
    line-height: 1;
  }

  a, button, svg {
    transition: ${Timing.TransitionControlElements}
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  button {
    border: none;
    background: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  b {
    font-weight: bold;
  }

  input {
    border: none;
    outline: none;
  }

  /* Chrome autofill background hack */
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
  }
  input[data-autocompleted] {
      background-color: transparent !important;
  }

  ul, li {
    margin: 0;
    padding: 0;
  }

  mark {
    /* background-color: ${Colors.Primary}; */
    color: ${Colors.Text};
    /* .label-color-000099 {
    background-color: #000099;
    }
    .label-color-0F122C {
    background-color: #0F122C;
    }
    .label-color-1ABC9C {
    background-color: #1ABC9C;
    }
    .label-color-16A085 {
    background-color: #16A085;
    }
    .label-color-34495E {
    background-color: #34495E;
    }
    .label-color-2C3E50 {
    background-color: #2C3E50;
    }
    .label-color-F1C40F {
    background-color: #F1C40F;
    }
    .label-color-F39C12 {
    background-color: #F39C12;
    }
    .label-color-E67E22 {
    background-color: #E67E22;
    }
    .label-color-D35400 {
    background-color: #D35400;
    } */
  }

  [data-label] {
    &:after {
      content: attr(data-label);
    }
  }

`

export default AppStyles
