import { Navigate, Route, Routes } from 'react-router-dom'
import {
  Controversy,
  Controversies,
  Dashboard,
  Case,
  Cases,
  Fingerprints,
  RequestNewSource,
  Sources
} from 'pages'

const AuthRoutes = () => (
  <Routes>
    <Route element={<Controversy />} path="/controversies/:id" />
    <Route element={<Controversies />} path="/controversies" />
    <Route element={<Dashboard />} path="/dashboard" />
    <Route element={<Case />} path="/cases/:id" />
    <Route element={<Cases />} path="/cases" />
    <Route element={<Fingerprints />} path="/fingerprints" />
    <Route element={<Sources />} path="/sources" />
    <Route element={<RequestNewSource />} path="/sources/request" />
    <Route element={<Navigate replace to="/dashboard" />} path="*" />
  </Routes>
)

export default AuthRoutes
