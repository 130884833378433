import { CardMetric } from 'components'
import { CardMap, MapImage, Wrapper } from './Insights.styles'
import map from './map.png'

const Insights = () => {
  return (
    <Wrapper>
      <CardMap>
        <MapImage src={map} />
      </CardMap>
      <CardMetric label="Regulated Entities" metric="34" />
      <CardMetric label="Jurisdications" metric="16" />
      <CardMetric label="Releveant competent Authorities" metric="30+" />
    </Wrapper>
  )
}

export default Insights
