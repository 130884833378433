import { FC, ReactNode } from 'react'

import { AccordionHeaderContentWrapper, AccordionIcon, AccordionTrigger } from './Accordion.styles'

export const AccordionHeader: FC<{ children?: ReactNode; className?: string }> = ({
  children,
  className
}) => {
  return (
    <AccordionTrigger className={className}>
      <AccordionHeaderContentWrapper>{children}</AccordionHeaderContentWrapper>
      <AccordionIcon />
    </AccordionTrigger>
  )
}
