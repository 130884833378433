import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://ylyyftbblwazdzuplgoj.supabase.co'
const supabaseAnonKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlseXlmdGJibHdhemR6dXBsZ29qIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjkzODk4NTAsImV4cCI6MTk4NDk2NTg1MH0.DmdK4tLtjysjz51N46a4A9UrZQ3yosU5hJKYObwSl3U'

// Exposing keys is fine due to Postgres row level security
const apiClient = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false
  }
})

export default apiClient
