import styled from 'styled-components'
import { Card as BaseCard } from 'components'
import { Colors, FontSizes } from 'theme'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(12rem, 1fr));
  grid-gap: 1.5rem;
  margin-top: 3rem;
`

export const Cards = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
`

export const Card = styled(BaseCard)`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const TwoColumns = styled(Column)`
  grid-column: span 2;
`

export const Heading = styled.span`
  color: ${Colors.Text};
  font-size: ${FontSizes.Base};
  font-weight: 800;
  margin-bottom: 1.5rem;
`

export const Metric = styled.span`
  color: ${Colors.Primary};
  font-size: 2rem;
  font-weight: bold;
`

export const Label = styled.span`
  color: ${Colors.TextLight};
  font-size: ${FontSizes.Base};
  margin: 0.75rem 0 0;
`

export const ChartWrapper = styled.div`
  width: 100%;
`

export const Center = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`
