import { useState } from 'react'
import loginImg from 'assets/img/login.jpg'
import logoImg from 'assets/img/logo.png'
import { useSignInMutation } from 'data-fetcher'
import { FormWrapper, Logo, PosterImage, SubmitButton, TextInput, Wrapper } from './Login.styles'
import { Register } from './components'
import { useLocation } from 'react-router-dom'

const Login = () => {
  const location = useLocation()
  const { search } = location
  const isRegister = search.includes('register')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { isLoading: isSigningIn, mutate: signIn } = useSignInMutation()

  const handleSignIn = () => {
    signIn({ email, password })
  }

  if (isRegister) {
    return <Register />
  }

  return (
    <Wrapper>
      <FormWrapper>
        <Logo src={logoImg} />

        <TextInput
          inverted
          label="Username"
          onChange={input => setEmail(input)}
          placeholder="user@provider.com"
          size="large"
          type="text"
          value={email}
        />

        <TextInput
          inverted
          label="Password"
          onChange={input => setPassword(input)}
          placeholder="******"
          size="large"
          type="password"
          value={password}
        />

        <SubmitButton isLoading={isSigningIn} onClick={handleSignIn} size="large">
          Login
        </SubmitButton>
      </FormWrapper>
      <PosterImage alt="Eingang Deutsche Börse AG" src={loginImg} />
    </Wrapper>
  )
}

export default Login
