import { Card as BaseCard, Loader as BaseLoader, EmptyNote, LoadMore, Meta } from 'components'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Colors, FontSizes } from 'theme'

export const Card = styled(BaseCard)`
  margin-top: 2rem;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
`

export const ListItem = styled.div``

export const MetaInformation = styled(Meta)`
  margin-bottom: 0.375rem;
`

export const Title = styled(Link)`
  color: ${Colors.Text};
  font-size: ${FontSizes.Base};
  font-weight: 600;
  margin: 0.375rem 0 0.5rem;
  padding-bottom: 0.125rem;

  &:hover {
    color: ${Colors.Primary};
  }
`

export const Text = styled.p`
  margin-top: 0.75em;
`

export const LabelsWrapper = styled.div`
  margin-top: 1rem;
`

export const LabelsText = styled.span`
  display: block;
  font-size: ${FontSizes.Small};
  line-height: 1.6;
`

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  gap: 0.625rem;
  margin-top: 1.25rem;
`

const sharedNoteStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 25vh;
  width: 100%;
`

export const NoData = styled(EmptyNote)`
  ${sharedNoteStyles};
`

export const Loader = styled(BaseLoader)`
  ${sharedNoteStyles};
`

export const ControversiesLoadMore = styled(LoadMore)`
  margin: 4rem 0 1rem;
`
