import { Keys } from 'config'
import { log } from 'shared'
import { Note } from 'types'

import apiClient from '../../apiClient'
import { Database } from '../../api.types'
import { isBadResponseStatus } from '../../api.helpers'

export type GetNotes = {
  caseId: string
  visibility: 'private' | 'public'
}

const getNotes = async ({ caseId, visibility }: GetNotes): Promise<Array<Note>> => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getNotes', { onlineUserId })

  const query = apiClient
    .from<Keys.Notes, Database['public']['Tables'][Keys.Notes]>(Keys.Notes)
    .select('*')
    .eq('case', caseId)
    .eq('public', visibility === 'public' ? true : false)

  if (visibility === 'private') {
    query.eq('creator', onlineUserId)
  }

  const { data: notesData, error, status } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(`getNotes failed with status: ${status} and error: ${error}`)
  }

  const notes = notesData?.map(
    ({ created_at, id, text }) =>
      ({
        createdAt: created_at,
        id,
        text
      } as Note)
  )

  return notes ?? []
}

export default getNotes
