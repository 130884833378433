import { AIGym } from '@intuitive-ai/ai-gym'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import styled from 'styled-components'
import { Colors, FontSizes } from 'theme'
import Meta from '../../Meta'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const MetaInformation = styled(Meta)`
  margin-bottom: 0.375rem;
`

export const Title = styled(AIGym)`
  display: block;
  font-size: ${FontSizes.Base};
  font-weight: 700;
  line-height: 1.4;
  margin: 0.375rem 0 1.5rem;
`

export const Text = styled(AIGym)`
  display: block;
  line-height: 1.4;
`

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  gap: 0.625rem;
  margin-top: 2rem;
`

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const DialogInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0 1.5rem;
`

export const DialogButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const DialogHeadline = styled.span`
  font-weight: bold;
`

export const DialogOr = styled.span`
  font-size: ${FontSizes.Small};
  font-weight: bold;
  display: block;
  margin: 0.5rem 0;
  text-align: center;
  text-transform: uppercase;
`

export const ReadMoreLessButton = styled.button`
  align-items: center;
  display: flex;
  gap: 0.25rem;
`

export const ReadMoreLessLabel = styled.span`
  align-self: flex-start;
  color: ${Colors.Primary};
  font-size: ${FontSizes.Small};
  font-weight: 700;
  margin: 1rem 0;

  &:hover {
    color: ${Colors.PrimaryHover};
  }
`

export const ReadLessIcon = styled(MdExpandMore)`
  height: 1.125rem;
  width: 1.125rem;
`

export const ReadMoreIcon = styled(MdExpandLess)`
  height: 1.125rem;
  width: 1.125rem;
`
