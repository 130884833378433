import { Database } from 'api/api.types'
import { Keys } from 'config'
import { log } from 'shared'
import { Controversy, Range } from 'types'

import { isBadResponseStatus } from '../../api.helpers'
import apiClient from '../../apiClient'

export type ControversiesWithRelevanceProps = { relevant: boolean; range?: Range }

export const getControversiesWithRelevance = async ({
  range,
  relevant
}: ControversiesWithRelevanceProps): Promise<Array<Controversy>> => {
  const session = await apiClient.auth.getSession()
  const userId = session.data.session?.user.id

  log('🔍 API: getControversiesWithRelevance', { userId })

  let query = apiClient
    .from<Keys.Controversies, Database['public']['Tables'][Keys.Controversies]>(Keys.Controversies)
    .select(
      `
    id,
    title,
    fulltext,
    date,
    source,
    url,
    relevance!inner(*)
  `
    )
    .eq('relevance.relevant', relevant)
    .eq('relevance.user', userId)
    .order('created_at', { ascending: false })

  if (range) {
    query = query.range(range.from, range.to)
  }

  const { data: controversiesData, error, status } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(`getControversies failed with status: ${status} and error: ${error}`)
  }

  const controversies = controversiesData?.map(
    ({ date, id, source, fulltext, title, url }) =>
      ({
        date,
        fulltext,
        id,
        source,
        title,
        url
      } as Controversy)
  )

  return controversies ?? []
}
