import styled from 'styled-components'
import { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Colors, FontSizes, Timing } from 'theme'

export const Wrapper = styled.nav`
  background: ${Colors.Navigation};
  box-shadow: 4px 0 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 17rem;
  z-index: 1;
`

export const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
`

export const Logo = styled.img`
  height: 1.875rem;
  width: auto;
`

export const Divider = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  height: 1px;
  margin: 0.5rem 0;
  width: 100%;
`

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  width: 100%;
`

const sharedItemStyles = css`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  height: 3.5rem;
  justify-content: flex-start;
  opacity: 0.7;
  padding: 0 2rem;
  transition: all ${Timing.TransitionControlElements};
  width: 100%;

  &:hover {
    opacity: 1;
  }
`

export const Item = styled(Link)<{ $active: boolean }>`
  ${sharedItemStyles};
  background: ${({ $active }) => ($active ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};
  opacity: ${({ $active }) => ($active ? 1 : 0.7)};

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

export const ExternalLinkItem = styled.a`
  ${sharedItemStyles};
`

export const Label = styled.span`
  color: #fff;
  font-size: ${FontSizes.Base};
  margin-top: 1px;
`

export const Footer = styled.footer`
  margin-top: auto;
  padding-bottom: 1rem;
  width: 100%;
`
