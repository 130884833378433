import { FC, useMemo, useState } from 'react'

import { useCreateLabeling, useGetDelabeling, useGetLabeling } from 'data-fetcher'
import { Controversy, Color, Labeling } from 'types'

import { RelevancyInput } from './components'
import {
  MetaInformation,
  ReadLessIcon,
  ReadMoreLessButton,
  ReadMoreIcon,
  ReadMoreLessLabel,
  Text,
  Title,
  Wrapper
} from './TextWithLabels.styles'
import { useAuth } from 'stores'
import { createId, stripText } from 'shared'

type ReducedLabel = { color: Color; id: string; name: string } // TODO: replace by label

const TextWithLabels: FC<{
  activeFingerprintId?: string
  activeFingerprintName?: string
  activeLabel?: ReducedLabel
  controversy: Controversy
  focusedLabelingId?: string
  includeAiLabeling?: boolean
  /** Strip text and show read more label after x characters */
  showReadMoreAfter?: number
  userId?: string // get labeling for other users, e.g. colleagues in same team
}> = ({
  activeFingerprintId,
  activeFingerprintName,
  activeLabel,
  controversy,
  focusedLabelingId,
  includeAiLabeling,
  showReadMoreAfter,
  userId
}) => {
  const {
    date: controversyDate,
    documentType: controversyDocumentType,
    fulltext: controversyFulltext,
    id: controversyId,
    source: controversySource,
    title: controversyTitle,
    url: controversyUrl
  } = controversy

  // The current user is not allowed to edit labelings for other users.
  const readOnly = Boolean(userId)

  const { userId: currentUserId } = useAuth()
  const { mutate: createLabeling } = useCreateLabeling()

  const { data: delabeling, isLoading: isLoadingDelabeling } = useGetDelabeling({
    controversyId,
    userId
  })
  const { data: labeling } = useGetLabeling({
    controversyId,
    includeAiLabeling,
    options: { disabled: isLoadingDelabeling },
    userId
  })

  const [textCollapsed, setTextCollapsed] = useState(true)

  const handleTextHighlight = ({
    endIndex,
    highlightedText,
    isTitle,
    startIndex
  }: {
    endIndex: number
    highlightedText: string
    isTitle?: boolean
    startIndex: number
  }) => {
    if (activeFingerprintId && activeFingerprintName && activeLabel) {
      createLabeling([
        {
          controversyId: controversyId,
          color: activeLabel.color,
          creator: currentUserId,
          end: endIndex,
          fingerprintId: activeFingerprintId,
          fingerprintName: activeFingerprintName,
          id: createId(),
          labelId: activeLabel.id,
          labelName: activeLabel.name,
          start: startIndex,
          text: isTitle ? undefined : highlightedText,
          title: isTitle ? highlightedText : undefined
        }
      ])

      window.getSelection()?.removeAllRanges()
    }
  }

  const showReadMoreOrLess = showReadMoreAfter && showReadMoreAfter < controversyFulltext.length

  const delabeledIds = useMemo(() => delabeling?.map(({ labeling }) => labeling), [delabeling])

  const emptyLabelings: Labeling[] = []
  const labelingWithoutDelabeling = useMemo(
    () =>
      labeling?.reduce((acc, labeling) => {
        if (showReadMoreAfter && labeling.end > showReadMoreAfter && textCollapsed) {
          return acc
        }

        if (!delabeledIds?.includes(labeling.id)) {
          return [...acc, labeling]
        }

        return acc
      }, emptyLabelings),
    [delabeledIds, labeling, showReadMoreOrLess, textCollapsed]
  )

  return (
    <Wrapper>
      <MetaInformation
        date={controversyDate}
        documentType={controversyDocumentType}
        source={controversySource}
        url={controversyUrl}
      />
      <Title
        activeLabelId={activeLabel?.id}
        hasFocusedLabel={Boolean(focusedLabelingId)}
        highlightingEnabled
        highlightedTexts={labelingWithoutDelabeling
          ?.filter(({ title }) => Boolean(title))
          ?.map(({ color, end, id, labelName, start }) => ({
            color: color.passive,
            end,
            isFocused: focusedLabelingId === id,
            labelName,
            start
          }))}
        onTextHighlight={({ endIndex, highlightedText, startIndex }) => {
          if (!readOnly) {
            handleTextHighlight({
              endIndex,
              highlightedText,
              isTitle: true,
              startIndex
            })
          }
        }}
        text={controversyTitle}
      />
      <Text
        activeLabelId={activeLabel?.id}
        hasFocusedLabel={Boolean(focusedLabelingId)}
        highlightingEnabled
        highlightedTexts={labelingWithoutDelabeling
          ?.filter(({ text }) => Boolean(text))
          ?.map(({ color, end, id, labelName, start }) => ({
            color: color.passive,
            end,
            isFocused: focusedLabelingId === id,
            labelName,
            start
          }))}
        onTextHighlight={({ endIndex, highlightedText, startIndex }) => {
          if (!readOnly) {
            handleTextHighlight({
              endIndex,
              highlightedText,
              startIndex
            })
          }
        }}
        text={
          showReadMoreOrLess && textCollapsed
            ? stripText(controversyFulltext, showReadMoreAfter)
            : controversyFulltext
        }
      />

      {showReadMoreOrLess && !textCollapsed && (
        <ReadMoreLessButton onClick={() => setTextCollapsed(true)}>
          <ReadMoreLessLabel>Show less</ReadMoreLessLabel>
          <ReadMoreIcon />
        </ReadMoreLessButton>
      )}

      {showReadMoreOrLess && textCollapsed && (
        <ReadMoreLessButton onClick={() => setTextCollapsed(false)}>
          <ReadMoreLessLabel>Read more</ReadMoreLessLabel>
          <ReadLessIcon />
        </ReadMoreLessButton>
      )}

      <RelevancyInput
        controversy={controversy}
        readOnly={readOnly}
        userId={userId ?? currentUserId}
      />
    </Wrapper>
  )
}

export default TextWithLabels
