import { useNavigate } from 'react-router-dom'
import { EmptyNote, Layout, Loader, TabsContent, TabsList, TabsRoot } from 'components'
import { useGetFingerprints } from 'data-fetcher'
import { Insights, Operations } from './components'

const Dashboard = () => {
  const { data: fingerprints, isLoading } = useGetFingerprints()
  const navigate = useNavigate()

  return (
    <Layout>
      {isLoading ? (
        <Loader fullPageCenter />
      ) : !fingerprints?.length ? (
        <EmptyNote
          buttonLabel="Create Fingerprint"
          copy="Seems like you haven’t created any fingerprints yet."
          fullPageCenter
          onButtonClick={() => navigate('/fingerprints')}
          icon="fingerprint"
        />
      ) : (
        <TabsRoot defaultValue="operations">
          <TabsList
            tabs={[
              { name: 'Operations', value: 'operations' },
              { name: 'Insights', value: 'insights' }
            ]}
          />
          <TabsContent value="operations">
            <Operations />
          </TabsContent>
          <TabsContent value="insights">
            <Insights />
          </TabsContent>
        </TabsRoot>
      )}
    </Layout>
  )
}

export default Dashboard
