import { FC, ReactNode } from 'react'
import { Header, Navigation, TopBar } from './components'
import { Content, Main, Wrapper } from './Layout.styles'

const Layout: FC<{
  children?: ReactNode
  componentEnd?: ReactNode
  goBackHref?: string
  subtitle?: string
  title?: string
}> = ({ children, componentEnd, goBackHref, subtitle, title }) => {
  return (
    <Wrapper>
      <Navigation />
      <Main>
        <TopBar goBackHref={goBackHref} />
        <Content>
          {(componentEnd || subtitle || title) && (
            <Header componentEnd={componentEnd} subtitle={subtitle} title={title} />
          )}

          {children}
        </Content>
      </Main>
    </Wrapper>
  )
}

export default Layout
