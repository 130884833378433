import { Portal, Provider, Root, Trigger } from '@radix-ui/react-tooltip'
import { FC, PropsWithChildren } from 'react'
import { TooltipArrow, TooltipContent } from './Tooltip.styles'

const Tooltip: FC<PropsWithChildren & { label: string }> = ({ children, label }) => {
  return (
    <Provider>
      <Root delayDuration={50}>
        <Trigger asChild>{children}</Trigger>
        <Portal>
          <TooltipContent sideOffset={5}>
            {label}
            <TooltipArrow />
          </TooltipContent>
        </Portal>
      </Root>
    </Provider>
  )
}

export default Tooltip
