import { Keys } from 'config'
import { log } from 'shared'

import { isBadResponseStatus } from '../../api.helpers'
import { Database } from '../../api.types'
import apiClient from '../../apiClient'

export type RelevanceCountProps = { relevant?: boolean }

const getRelevanceCount = async ({ relevant }: RelevanceCountProps): Promise<number> => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getRelevanceCount', { onlineUserId })

  let query = apiClient
    .from<Keys.Relevance, Database['public']['Tables'][Keys.Relevance]>(Keys.Relevance)
    .select('*', { count: 'exact' })
    .eq('user', onlineUserId)

  if (relevant !== undefined) {
    query = query.eq('relevant', Boolean(relevant))
  }

  const { count, error, status } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(`getRelevanceCount failed with status: ${status} and error: ${error}`)
  }

  return count ?? 0
}

export default getRelevanceCount
