import { Link, useLocation } from 'react-router-dom'
import logo from 'assets/img/logo.png'
import {
  Divider,
  ExternalLinkItem,
  Footer,
  Header,
  Item,
  Items,
  Label,
  Logo,
  Wrapper
} from './Navigation.styles'
import { navigationItems } from './Navigation.data'
import { Fragment } from 'react'

const Navigation = () => {
  const location = useLocation()

  const { pathname } = location

  return (
    <Wrapper>
      <Header>
        <Link to="/">
          <Logo src={logo} />
        </Link>
      </Header>

      <Items>
        {navigationItems.map(({ icon: Icon, label, to }, index) => (
          <Fragment key={`nav-item-${label}`}>
            {(index === 1 || index === 3) && <Divider />}
            <Item $active={pathname.toLowerCase().includes(to)} to={to}>
              {Icon}
              <Label>{label}</Label>
            </Item>
          </Fragment>
        ))}
      </Items>

      <Footer>
        <ExternalLinkItem href="mailto:support@intuitive-ai.de">
          <Label>Support</Label>
        </ExternalLinkItem>
      </Footer>
    </Wrapper>
  )
}

export default Navigation
