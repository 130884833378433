import { FC, PropsWithChildren, useEffect, useMemo } from 'react'
import { useHover } from '@intuitive-ai/hooks'
import styled from 'styled-components'
import { FiX } from 'react-icons/fi'
import { RiUser3Line } from 'react-icons/ri'

import {
  AccordionContent,
  AccordionHeader as BaseAccordionHeader,
  AccordionItem,
  AccordionRoot,
  EmptyNote,
  Label
} from 'components'
import { useDeleteLabeling, useGetLabeling } from 'data-fetcher'
import { groupBy } from 'shared'
import { Colors, FontSizes } from 'theme'
import { Labeling } from 'types'

const HoverableTableRow: FC<PropsWithChildren & { onHoverToggle: (hovering: boolean) => void }> = ({
  children,
  onHoverToggle
}) => {
  const { ref, value } = useHover<HTMLTableRowElement>()

  useEffect(() => {
    onHoverToggle(value)
  }, [value])

  return <TableRow ref={ref}>{children}</TableRow>
}

const AvailableLabels: FC<{
  controversyId: string
  onLabelingChange?: (labels: Labeling[]) => void
  onLabelingHovered: (options: { hovered: boolean; labeling?: string }) => void
}> = ({ controversyId, onLabelingChange, onLabelingHovered }) => {
  const { data: labels, isLoading, status } = useGetLabeling({ controversyId })
  const { mutate: deleteLabeling } = useDeleteLabeling()

  useEffect(() => {
    if (status === 'success') {
      onLabelingChange?.(labels)
    }
  }, [labels, status])

  const labelsByFingerprint = useMemo(
    () => groupBy(labels ?? [], ({ fingerprintName }) => fingerprintName),
    [labels]
  )

  return (
    <>
      {isLoading ? null : !labels?.length ? (
        <EmptyNote copy="No labels set." icon="label" />
      ) : (
        <Groups>
          {Array.from(labelsByFingerprint).map(([fingerprintName, labels], i) => (
            <Group key={`available-labels-fingerprint-group-${fingerprintName}-${i}`}>
              <AccordionRoot collapsible defaultValue="suggested-labels" type="single">
                <AccordionItem value="suggested-labels">
                  <AccordionHeader>
                    <Row>
                      <MyLabelingsIcon />
                      <Headline>My Labels</Headline>
                    </Row>
                  </AccordionHeader>
                  <AccordionContent>
                    <Fingerprint>{fingerprintName}</Fingerprint>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableHeading>Label</TableHeading>
                          <TableHeading>Text</TableHeading>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {labels.map(
                          (
                            {
                              controversyId,
                              color,
                              fingerprintId,
                              id,
                              labelId,
                              labelName,
                              start,
                              end,
                              text,
                              title
                            },
                            i
                          ) => (
                            <HoverableTableRow
                              onHoverToggle={hovered => {
                                onLabelingHovered({ hovered, labeling: id })
                              }}
                              key={`available-labels-${fingerprintName}-${labelName}-${text}-${title}-${i}`}
                            >
                              <TableData>
                                <TableDataInnerWrapper>
                                  <Label color={color} id={labelId} name={labelName} />
                                </TableDataInnerWrapper>
                              </TableData>
                              <TableData>{text ?? title}</TableData>
                              <TableData>
                                <RemoveButton
                                  onClick={() =>
                                    deleteLabeling({
                                      controversyId,
                                      fingerprintId,
                                      labelId,
                                      start,
                                      end
                                    })
                                  }
                                >
                                  <FiX size={14} strokeWidth={1.5} />
                                </RemoveButton>
                              </TableData>
                            </HoverableTableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </AccordionContent>
                </AccordionItem>
              </AccordionRoot>
            </Group>
          ))}
        </Groups>
      )}
    </>
  )
}

export default AvailableLabels

const Groups = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const Group = styled.div``

const AccordionHeader = styled(BaseAccordionHeader)`
  justify-content: space-between;
`

const Headline = styled.span`
  display: block;
  font-size: ${FontSizes.Base};
  font-weight: 600;
`

const Fingerprint = styled.span`
  display: block;
  font-size: ${FontSizes.Small};
  font-weight: bold;
  margin: 2rem 0 1rem;
`

const Table = styled.table`
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: auto;
`

const TableHead = styled.thead``

const TableHeading = styled.th`
  font-size: ${FontSizes.Small};
  padding: 0.5rem;
  text-align: left;
`

const TableData = styled.td`
  font-size: ${FontSizes.Small};
  padding: 0.5rem;
  width: auto;
`

const TableRow = styled.tr`
  ${TableData} {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  ${TableHeading} {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`

const TableBody = styled.tbody`
  ${TableRow} {
    border-top: 1px solid ${Colors.Divider};
  }
`

const RemoveButton = styled.button`
  color: ${Colors.Text};
  padding: 0.25rem;

  &:hover {
    color: ${Colors.Destructive};
  }
`

const TableDataInnerWrapper = styled.div`
  display: flex;
`

const Row = styled.div`
  align-items: center;
  display: flex;
`

const MyLabelingsIcon = styled(RiUser3Line)`
  color: ${Colors.Text};
  height: 1rem;
  margin: 0 0.5rem 0 0;
  width: 1rem;
`
