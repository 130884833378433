import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateSource, UpdateSource } from 'api'
import { Keys } from 'config'
import { logError } from 'shared'
import { useToast } from 'stores'
import { Source, SourceStatus } from 'types'

const disabledSourceStatus: SourceStatus = 'disabled'

export const useRequestExistingSource = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(
    ({ sourceId }) => updateSource({ sourceId, updatedSource: { status: 'requested' } }),
    {
      onMutate: async ({ sourceId }: Pick<UpdateSource, 'sourceId'>) => {
        await queryClient.cancelQueries([Keys.Sources, disabledSourceStatus])

        // Remove requested source from disabled
        const previousSources =
          queryClient.getQueryData<Source[]>([Keys.Sources, disabledSourceStatus]) ?? []
        const updatedSources = previousSources.filter(
          previousSource => previousSource.id !== sourceId
        )
        queryClient.setQueryData([Keys.Sources, disabledSourceStatus], updatedSources)

        return { previousSources }
      },
      onError: (error, _, context) => {
        queryClient.setQueryData(
          [Keys.Sources, disabledSourceStatus],
          context?.previousSources ?? []
        )
        logError('updateSource failed', { error })
        showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
      },
      onSettled: () => {
        queryClient.invalidateQueries({ exact: false, queryKey: [Keys.Sources] })
      },
      onSuccess: () => {
        showToast({
          description: 'Your request has been submitted successfully',
          title: 'Request succesful',
          type: 'success'
        })
      }
    }
  )
}
