export enum Keys {
  Auth = 'auth',
  BusinessUnits = 'units',
  CaseBusinessUnits = 'cases_units',
  Cases = 'cases',
  CasesPaginated = 'cases-paginated',
  Colors = 'colors',
  Controversies = 'controversies',
  ControversiesIrrelevant = 'controversies-irrelevant',
  ControversiesPaginated = 'controversies-paginated',
  ControversiesPaginatedIrrelevant = 'controversies-paginated-irrelevant',
  ControversiesPaginatedRelevant = 'controversies-paginated-relevant',
  ControversiesRelevant = 'controversies-relevant',
  ControversyLabelingUsers = 'controversy_labeling_users',
  Delabeling = 'delabeling',
  Documents = 'documents',
  Fingerprints = 'fingerprints',
  Labels = 'labels',
  Labeling = 'labeling',
  Notes = 'notes',
  Profiles = 'profiles',
  Relevance = 'relevance',
  Sources = 'sources',
  TopLabels = 'top_labels'
}
