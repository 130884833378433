import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Label, Loader, LoadingWrapper, Wrapper } from './Button.styles'

const Button: FC<{
  as?: 'div' | undefined
  children?: ReactNode
  className?: string
  disabled?: boolean
  hollow?: boolean
  href?: string
  iconStart?: ReactNode
  isLoading?: boolean
  onClick?: () => void
  passive?: boolean
  size?: 'base' | 'large'
}> = ({
  as,
  children,
  className,
  disabled,
  hollow,
  href,
  iconStart: IconStart,
  isLoading,
  onClick,
  passive,
  size
}) => {
  return (
    <Wrapper
      as={as ? as : href ? Link : 'button'}
      className={className}
      $disabled={disabled}
      $hasLeftIcon={Boolean(IconStart)}
      $hollow={hollow}
      onClick={onClick}
      $passive={Boolean(passive)}
      $size={size ?? 'base'}
      to={href}
    >
      {IconStart}
      <Label>{children}</Label>

      {isLoading && (
        <LoadingWrapper>
          <Loader $passive={Boolean(passive)} />
        </LoadingWrapper>
      )}
    </Wrapper>
  )
}

export default Button
