import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { Colors, FontSizes } from 'theme'
import Card from '../Card'

const CardMetric: FC<{ children?: ReactNode; label: string; metric: string }> = ({
  children,
  label,
  metric
}) => (
  <Wrapper $centerContent={!children}>
    <Metric>{metric}</Metric>
    <Label>{label}</Label>
    {children}
  </Wrapper>
)

export default CardMetric

const Wrapper = styled(Card)<{ $centerContent: boolean }>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: ${({ $centerContent }) => ($centerContent ? 'center' : 'flex-start')};
  gap: 0.375rem;
`

const Label = styled.span`
  color: ${Colors.TextLight};
  font-size: ${FontSizes.Small};
`

const Metric = styled.span`
  color: ${Colors.Primary};
  font-size: 2rem;
  font-weight: bold;
`
