import styled from 'styled-components'
import { Button, TextInput as BaseTextInput } from 'components'
import { Colors } from 'theme'

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
`

export const Logo = styled.img`
  height: 2rem;
  margin-bottom: 3rem;
  width: auto;
`

export const FormWrapper = styled.div`
  align-items: center;
  background: ${Colors.Navigation};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  max-width: 26rem;
  padding: 0 4rem;
  width: 100%;
`

export const PosterImage = styled.img`
  flex: 1;
  object-fit: cover;
  object-position: center;
`

export const TextInput = styled(BaseTextInput)`
  margin-top: 1rem;
  width: 100%;
`

export const SubmitButton = styled(Button)`
  margin-top: 2rem;
  width: 100%;
`
