import styled from 'styled-components'
import { BorderRadius, Colors, FontSizes } from 'theme'

export const Title = styled.span`
  display: block;
  font-size: ${FontSizes.Base};
  font-weight: 700;
`

export const Wrapper = styled.div<{ href?: string; $inverted?: boolean }>`
  background: ${({ $inverted }) => ($inverted ? Colors.Navigation : Colors.Surface)};
  border-radius: ${BorderRadius.Tiles};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.03);
  padding: 2rem;

  ${Title} {
    color: ${({ $inverted }) => ($inverted ? '#fff' : Colors.Text)};
  }
`

export const Row = styled.div`
  align-items: center;
  display: flex;
  gap: 0.375rem;
  margin-bottom: 2rem;
`

export const ComponentRightWrapper = styled.div`
  margin-left: auto;
`
