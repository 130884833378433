import { Keys } from 'config'
import { logError } from 'shared'
import { Labeling } from 'types'
import { Database } from '../../api.types'
import apiClient from '../../apiClient'

const createLabeling = async (labelings: Array<Labeling & { suggested_by_ai?: boolean }>) => {
  const { data, error } = await apiClient
    .from<Keys.Labeling, Database['public']['Tables'][Keys.Labeling]>(Keys.Labeling)
    .insert(
      labelings.map(
        ({
          controversyId,
          creator,
          end,
          fingerprintId,
          labelId,
          start,
          suggested_by_ai,
          text,
          title
        }) => ({
          controversy: controversyId,
          creator,
          end,
          fingerprint: fingerprintId,
          label: labelId,
          start,
          suggested_by_ai,
          text,
          title
        })
      )
    )

  if (error) {
    logError('createLabeling failed', { errorMessage: error.message })
    throw new Error(error.message)
  }
  return data
}

export default createLabeling
