import { FC, useState, ReactNode } from 'react'
import { Provider as ToastProvider } from '@radix-ui/react-toast'
import ToastContext, { ShowToastProps } from './ToastContext'
import { ToastDescription, ToastRoot, ToastTitle, ToastViewport } from './Toast.styles'

const Toast: FC<{ children?: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [toastDescription, setToastDescription] = useState<string | undefined>()
  const [toastTitle, setToastTitle] = useState<string | undefined>()
  const [_, setToastType] = useState<'success' | 'error'>('success') // Keep for potential future updates to appearance

  const reset = () => {
    setOpen(false)
    setToastDescription(undefined)
    setToastTitle(undefined)
  }

  const showToast = ({ description, title, type }: ShowToastProps) => {
    setOpen(true)
    setToastDescription(description)
    setToastTitle(title)
    if (type) {
      setToastType(type)
    }
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      <ToastProvider swipeDirection="right">
        {children}

        <ToastRoot onOpenChange={reset} open={open}>
          <ToastTitle>{toastTitle}</ToastTitle>
          <ToastDescription>{toastDescription}</ToastDescription>
        </ToastRoot>
        <ToastViewport />
      </ToastProvider>
    </ToastContext.Provider>
  )
}

export default Toast
