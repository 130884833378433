import styled from 'styled-components'
import { Colors, FontSizes } from 'theme'

export const RelevancyIndicator = styled.div<{ clickEnabled?: boolean; relevant?: boolean }>`
  align-items: center;
  align-self: flex-start;
  color: ${({ relevant }) => (relevant ? Colors.Primary : Colors.TextLight)};
  cursor: ${({ clickEnabled }) => (clickEnabled ? 'pointer' : 'default')};
  display: flex;
  font-size: ${FontSizes.XSmall};
  font-weight: bold;
  gap: ${({ relevant }) => (relevant ? '0.375rem' : '0.25rem')};
  margin-top: 1.75rem;
  padding-top: 0.25rem;
`
