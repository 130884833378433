import { useState } from 'react'
import loginImg from 'assets/img/login.jpg'
import logoImg from 'assets/img/logo.png'
import { useSignUpMutation } from 'data-fetcher'
import { FormWrapper, Logo, PosterImage, SubmitButton, TextInput, Wrapper } from '../Login.styles'

const Register = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [team, setTeam] = useState('56e07606-c94e-44bb-9eb0-c1cd4ae889f8')

  const { isLoading: isSigningUp, mutate: signUp } = useSignUpMutation()

  const handleSignUp = () => {
    signUp({ email, password, team })
  }

  if (isSigningUp) {
    return <span>Loading...</span>
  }

  return (
    <Wrapper>
      <FormWrapper>
        <Logo src={logoImg} />

        <TextInput
          inverted
          label="Username"
          onChange={input => setEmail(input)}
          placeholder="user@provider.com"
          size="large"
          type="text"
          value={email}
        />

        <TextInput
          inverted
          label="Password"
          onChange={input => setPassword(input)}
          placeholder="******"
          size="large"
          type="password"
          value={password}
        />

        <TextInput
          inverted
          label="Team"
          onChange={input => setTeam(input)}
          placeholder="Team Id"
          size="large"
          type="text"
          value={team}
        />

        <SubmitButton onClick={handleSignUp} size="large">
          Register
        </SubmitButton>
      </FormWrapper>
      <PosterImage alt="Eingang Deutsche Börse AG" src={loginImg} />
    </Wrapper>
  )
}

export default Register
