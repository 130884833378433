import styled, { css } from 'styled-components'
import { Card as BaseCard, EmptyNote, Loader as BaseLoader } from 'components'
import { Colors, FontSizes } from 'theme'

export const Card = styled(BaseCard)`
  margin-top: 2rem;
`

export const Table = styled.table`
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: auto;
`

export const TableRow = styled.tr`
  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }
`

export const TableHead = styled.thead`
  ${TableRow} {
    &:hover {
      background: initial;
    }
  }
`

export const TableHeading = styled.th`
  font-size: ${FontSizes.BaseInput};
  padding: 1.25rem 0.5rem;
  text-align: left;
`

export const TableBody = styled.tbody`
  ${TableRow} {
    border-top: 1px solid ${Colors.Divider};
  }
`

export const TableData = styled.td`
  font-size: ${FontSizes.Base};
  padding: 1.25rem 0.5rem;
`

export const TableDataInnerWrapper = styled.div`
  display: flex;
`

const sharedNoteStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 25vh;
  width: 100%;
`

export const NoData = styled(EmptyNote)`
  ${sharedNoteStyles};
`

export const Loader = styled(BaseLoader)`
  ${sharedNoteStyles};
`

export const Link = styled.a`
  color: ${Colors.Text};

  &:hover {
    text-decoration: underline;
  }
`

export const InlineButton = styled.button`
  color: ${Colors.Primary};
  font-weight: bold;
  font-size: ${FontSizes.XSmall};
`

export const Note = styled.span`
  color: ${Colors.TextLight};
  font-size: 0.75rem;
`
