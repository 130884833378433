import { FC } from 'react'
import { formatDate } from 'shared'
import ExternalLink from '../ExternalLink'
import { Label, Wrapper } from './Meta.styles'

const Meta: FC<{
  className?: string
  date?: Date | number | string
  documentType?: string
  source?: string
  url?: string
}> = ({ className, date, documentType, source, url }) => {
  const formattedDate = formatDate(date)
  return (
    <Wrapper className={className}>
      {Boolean(formattedDate) && <Label>{formattedDate}</Label>}
      {Boolean(url) && (
        <ExternalLink
          href={url!}
          label={`${source ?? url!}${documentType ? ` - ${documentType}` : ''}`}
        />
      )}
    </Wrapper>
  )
}

export default Meta
